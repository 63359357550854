.nav-linkk {
  color: inherit; /* Use the default text color */
  text-decoration: none; /* Remove underline */
}

.nav-linkk:hover {
  color: inherit; /* Keep the default text color on hover */
  text-decoration: none !important; /* Ensure no underline on hover */
}
.card-img-top {
  height: 160px; /* Fixed height for all images */
  object-fit: cover; /* Ensures the image covers the entire area */
  width: 100%; /* Makes sure the image spans the full width of the card */
}

.gold-star {
  color: gold;
}
.logo-img {
  width: 60px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%; /* Make it circular */
  object-fit: cover; /* Ensure the image covers the entire circular area */
  margin-right: 10px;
}

.yellow-ss:hover {
  color: #ffec01;
}

.yellow-ss {
  color: white;
}

.white-ss:hover {
  color: rgb(255, 246, 114);
}

.white-ss {
  color: #ffec01;
}

.gradient-line {
  bottom: -10px; /* Adjust based on how far you want the line from the image */
  left: 0;
  width: 100%;
  height: 1px; /* Adjust the height of the line */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(51, 255, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.search-bar {
  position: relative;
  margin-left: 20px; /* Space between the search bar and nav links */
}

.search-input {
  padding: 10px 30px; /* Padding to accommodate the search icon */
  border-radius: 25px; /* Rounded edges */
  border: 1px solid #ced4da; /* Light border */
  outline: none; /* Remove outline on focus */
  transition: border-color 0.3s;
}

.search-input:focus {
  border-color: #007bff; /* Change border color on focus */
}

.search-icon {
  position: absolute;
  left: 10px; /* Position the icon inside the input */
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
  color: #007bff; /* Icon color */
}

.suggestions-list {
  position: absolute;
  top: 100%; /* Position below the input */
  background: white; /* Background color for suggestions */
  border: 1px solid #ced4da; /* Border for suggestions */
  border-radius: 10px 10px 10px 10px; /* Rounded bottom edges */
  max-height: 200px; /* Limit height */
  overflow-y: auto; /* Scroll if needed */
  z-index: 1000; /* Ensure it appears above other elements */
}

.suggestion-item {
  padding: 10px; /* Padding for suggestions */
  cursor: pointer; /* Cursor change on hover */
  transition: background-color 0.3s;
}

.suggestion-item:hover {
  background-color: #f8f9fa; /* Highlight on hover */
}

.image-text-container {
  margin-bottom: 5%;
  width: 100%;
  padding: 0 1%;
  opacity: 0; /* Start with opacity 0 for fade-in effect */
  animation: fadeIn 0.5s forwards; /* Fade-in animation */
}

.image-wrapper {
  box-shadow: 0px 0px 10px 5px orange;
  overflow: hidden;
  border-radius: 8px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: transform 0.3s, box-shadow 0.3s; /* Add transition for hover effects */
}

.image-wrapper:hover {
  transform: scale(1.05); /* Slightly zoom in on hover */
  box-shadow: 0px 0px 20px 10px orange; /* Increase shadow on hover */
}

.img-fluid {
  width: 100%; /* Full width of the parent container */
  height: auto;
}

.text-animation {
  padding: 20px;
  margin-top: 20px;
  word-break: break-word;
  transform: translateY(20px); /* Start slightly down */
  opacity: 0; /* Start with opacity 0 */
  animation: slideIn 0.5s forwards 0.3s; /* Slide-in animation */
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
  to {
    opacity: 1; /* Fade in to full opacity */
  }
}

/* Keyframes for slide-in effect */
@keyframes slideIn {
  to {
    transform: translateY(0); /* Slide to original position */
    opacity: 1; /* Fade in to full opacity */
  }
}

/* AgeVerificationModal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  max-width: 500px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.modal-content h2 {
  margin-bottom: 1rem;
}

.modal-content button {
  padding: 0.75rem 1.5rem;
  margin: 0.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.btn-yes {
  background-color: #4caf50;
  color: #fff;
}

.btn-no {
  background-color: #f44336;
  color: #fff;
}

.disabledV2 {
  opacity: 0.65; /* Make it look visually disabled */
  cursor: not-allowed;
  pointer-events: none; /* Prevent any mouse events */
}

/* ----------------------------------------------------------------- */
/* Background Image Animation */
.homeImages {
  border-image: fill 0 linear-gradient(#0002, #000);
  background-size: cover; /* Default for large screens: cover the container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent repeating the image */
  animation: slider 20s linear infinite;
  height: 100vh; /* Full viewport height for large screens */
}

@keyframes slider {
  0% {
    background-image: url("../../public/assets/Cover.jpg");
  }
  30% {
    background-image: url("../../public/assets/Cover\ 2.png");
  }
  60% {
    background-image: url("../../public/assets/main1 - Copy.jpg");
  }
  100% {
    background-image: url("../../public/assets/Cover.jpg");
  }
}

/* Mobile Specific Adjustments */
@media (max-width: 768px) {
  .homeImages {
    background-size: contain; /* Scale the image based on the width of the container */
    height: auto; /* Adjust the height based on the aspect ratio of the image */
    min-height: 200px; /* Set a minimum height for small screens to avoid the image being too small */
  }
}

/* --------------------------------------------------------------- */

.start {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.rotatedBody {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: transparent;
  font-family: sans-serif;
  overflow: hidden; /* Avoids content overflow when scrolling */
}

.rotatedContainer {
  position: relative;
  width: 200px;
  height: 350px;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  animation: rotate3D 20s infinite;
  overflow: visible; /* Ensure the shadow isn't clipped */
}

.rotatedContainer span {
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--i) * 45deg)) translateZ(400px);
  transition: transform 15s;
  will-change: transform; /* Apply will-change to span for optimization */
}
.rotatedContainer img {
  height: 350px;
  width: 200px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 15px 25px rgba(255, 255, 255, 0.233);
  will-change: transform, opacity; /* Let the browser know these properties are being animated */
}

@keyframes rotate3D {
  from {
    transform: perspective(1000px) rotateY(0deg);
  }
  to {
    transform: perspective(1000px) rotateY(360deg);
  }
}

/* --------------------------------------------------------------- */

.newBody {
  padding: 1%;
}

.homeContainer {
  margin: 35px 0 0 200px;
  width: 70%;
  height: 450px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.homeContainer img {
  width: 20%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid rgba(63, 63, 63, 0.684);
  transition: all ease-in-out 0.5s;
}

.homeContainer img:hover {
  width: 45%;
}
/* --------------------------------------------------------------- */
